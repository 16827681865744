<template>
  <tfoms-panel
    title="Чек-листы"
    hide-icon
    small
    v-bind="$attrs"
    :isClickLabel="false"
    style="position: relative"
  >
    <wrapper-loader circle v-if="loading"></wrapper-loader>
    <div class="analysis-tfoms__table analysis-tfoms__base-table">
      <base-tree-table
        :items="computedData"
        :headers="headers1"
        hide-default-footer
        notShowSelect
        nested
        not-filter
        @click:row="click"
        :item-class="
          (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
        "
        :sort-by="['Percent']"
        :sort-desc="[true]"
        tree-table-contents
        :exclude-settings-actions="['table', 'group']"
      >
      </base-tree-table>
    </div>
  </tfoms-panel>
</template>
<script>
import moment from "moment";

export default {
  components: {
    BaseTreeTable: () => import("@/components/base/BaseTreeTable"),
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
    wrapperLoader: () => import("@/components/base/WrapperLoader.vue"),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    refs: { type: Array, defaulta: null },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers1: [
        {
          value: "Name",
          text: "Чек-лист",
        },
        {
          value: "Percent",
          text: "Оценка",
          dataType: "numberBar",
          options: { append: "%", refs: this.refs },
        },
        {
          value: "Date",
          text: "Дата",
          displayText: (e) => moment(e, "DD.MM.YYYY").format("MMMM YYYY"),
        },
      ],
    };
  },
  computed: {
    computedData() {
      return this.data.map((e) => ({
        Id: e.CheckList.Id,
        Date: e.Date,
        Name: e.CheckList.Name,
        Percent: e.Percent,
        Children: e.CheckOperations.map((e2) => ({
          Name: e2.CheckOperationObjectName,
          Date: e2.Date,
          Id: e2.Id,
          Percent: e2.Percent,
          isCheckOperation: true,
        })),
      }));
    },
  },
  methods: {
    click(val) {
      this.$emit("input", val?.Id === this.value?.Id ? null : val);
    },
  },
};
</script>
